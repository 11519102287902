@font-face {
    font-family: 'URW Geometric Cond';
    src: url('../fonts/URWGeometricCondBold.woff2') format('woff2'),
        url('../fonts/URWGeometricCondBold.woff') format('woff'),
        url('../fonts/URW-Type-Foundry---URWGeometricCond-Bold.otf')
            format('opentype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'URW Geometric Cond';
    src: url('../fonts/URWGeometricCondBoldOblique.woff2') format('woff2'),
        url('../fonts/URWGeometricCondBoldOblique.woff') format('woff'),
        url('../fonts/URW-Type-Foundry---URWGeometricCond-BoldOblique.otf')
            format('opentype');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'URW Geometric Cond';
    src: url('../fonts/URWGeometricCondRegular.woff2') format('woff2'),
        url('../fonts/URWGeometricCondRegular.woff') format('woff'),
        url('../fonts/URW-Type-Foundry---URWGeometricCond-Regular.otf')
            format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'URW Geometric Cond';
    src: url('../fonts/URWGeometricCondRegularOblique.woff2') format('woff2'),
        url('../fonts/URWGeometricCondRegularOblique.woff') format('woff'),
        url('../fonts/URW-Type-Foundry---URWGeometricCond-RegularOblique.otf')
            format('opentype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'URW Geometric Ext';
    src: url('../fonts/URWGeometricExtRegular.woff2') format('woff2'),
        url('../fonts/URWGeometricExtRegular.woff') format('woff'),
        url('../fonts/URW-Type-Foundry---URWGeometricExt-Regular.otf')
            format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'URW Geometric Ext';
    src: url('../fonts/URWGeometricExtExtraBold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'URW Geometric Ext';
    src: url('../fonts/URWGeometricExtExtraBoldOblique.woff2') format('woff2'),
        url('../fonts/URWGeometricExtExtraBoldOblique.woff') format('woff'),
        url('../fonts/URW-Type-Foundry---URWGeometricExt-ExtraBoldOblique.otf')
            format('opentype');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'URW Geometric Ext';
    src: url('../fonts/URWGeometricExtRegularOblique.woff2') format('woff2'),
        url('../fonts/URWGeometricExtRegularOblique.woff') format('woff'),
        url('../fonts/URW-Type-Foundry---URWGeometricExt-RegularOblique.otf')
            format('opentype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'URW Geometric';
    src: url('../fonts/URWGeometric-SemiBold.woff2') format('woff2'),
        url('../fonts/URWGeometric-SemiBold.woff') format('woff'),
        url('../fonts/URW-Type-Foundry---URWGeometric-SemiBold.otf')
            format('opentype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'URW Geometric';
    src: url('../fonts/URWGeometric-Regular.woff2') format('woff2'),
        url('../fonts/URWGeometric-Regular.woff') format('woff'),
        url('../fonts/URW-Type-Foundry---URWGeometric-Regular.otf')
            format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'URW Geometric';
    src: url('../fonts/URWGeometric-RegularOblique.woff2') format('woff2'),
        url('../fonts/URWGeometric-RegularOblique.woff') format('woff'),
        url('../fonts/URW-Type-Foundry---URWGeometric-RegularOblique.otf')
            format('opentype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'URW Geometric';
    src: url('../fonts/URWGeometric-BoldOblique.woff2') format('woff2'),
        url('../fonts/URWGeometric-BoldOblique.woff') format('woff'),
        url('../fonts/URW-Type-Foundry---URWGeometric-BoldOblique.otf')
            format('opentype');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'URW Geometric';
    src: url('../fonts/URWGeometric-Bold.woff2') format('woff2'),
        url('../fonts/URWGeometric-Bold.woff') format('woff'),
        url('../fonts/URW-Type-Foundry---URWGeometric-Bold.otf')
            format('opentype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'URW Geometric Light';
    src: url('../fonts/URWGeometric-Light.woff2') format('woff2'),
        url('../fonts/URWGeometric-Light.woff') format('woff'),
        url('../fonts/URW-Type-Foundry---URWGeometric-Light.otf')
            format('opentype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'URW Geometric Ext';
    src: url('../fonts/URWGeometricExtExtraBold.woff') format('woff'),
        url('../fonts/URW-Type-Foundry---URWGeometricExt-ExtraBold.otf')
            format('opentype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

*:focus {
    outline: none;
}

#searchmaster::placeholder {
    color: #0b192c;
    opacity: 1;
}

body,
html {
    width: 100%;
    height: 100%;
}

div#root {
    max-width: 1400px;
    position: relative;
    margin: 0 auto;
    /* overflow-x: hidden; */
}

div.Toastify__toast-body a:link,
div.Toastify__toast-body a:visited,
div.Toastify__toast-body a:hover {
    color: #fff;
}

div.Toastify__toast-container {
    width: auto;
}

div.Toastify__toast {
    background: #30a773;
    padding: 20px 40px 20px 30px;
    color: #fff;
    font-weight: 800;
    font-family: URW Geometric Ext, sans-serif;
    box-shadow: 1px 1px 5px -2px #30a773;
    font-size: 20px;
}

div.Toastify__toast--error {
    background: #bd425a;
    box-shadow: 1px 1px 5px -2px #bd425a;
}

.Toastify__toast-icon {
    padding-right: 5px;
}

.Toastify__toast-icon svg {
    fill: #fff !important;
}

.Toastify__close-button,
.Toastify__close-button--light {
    color: #fff !important;
    position: absolute;
    top: 10px;
    right: 10px;
}

/* Plans and Pricing Overrides */

.plans-pricing-centered {
    text-align: center;
}

/* mobile layout style adjustments */

/* @media only screen and (max-width: 896px) {
} */
