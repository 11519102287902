body {
    margin: 0;
    background-color: '#f7f7f7' !important;
    height: '100%';
    width: '100%';
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body div * {
    font-family: 'URW Geometric Ext', 'Helvetica', 'Arial', sans-serif;
}
body .condensed-font-style * {
    font-family: 'URW Geometric Cond', 'Helvetica', 'Arial', sans-serif !important;
}
body .condensed-font-style p span {
    font-family: 'URW Geometric Ext', 'Helvetica', 'Arial', sans-serif !important;
    letter-spacing: 0;
}
body .extended-font-style {
    /* needed this style for impact score to be extended because it's inside the holdings table that uses the .condensed-font-style */
    font-family: 'URW Geometric Ext', 'Helvetica', 'Arial', sans-serif !important;
}

html {
    width: '100%';
    height: '100%';
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
